<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-checkbox
        class="base-checkbox"
        v-bind="$attrs"
        on-icon="mdi-check"
        off-icon=""
        color="red"
        hide-details
        v-on="$listeners"
    >
        <template v-for="(scope, slot) in $slots" :slot="slot">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </v-checkbox>
</template>

<script>
    export default {
        name: 'BaseCheckbox',
        inheritAttrs: false,
        model: {
            prop: 'input-value',
            event: 'change'
        },
        props: {
            type: null
        }
    }
</script>

<style lang="scss">
    .base-checkbox {
        .v-input--selection-controls__input {
            background: #fff;
            display: flex;
            justify-content: center;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            width: 18px;
            height: 18px;
        }
        &.v-input--selection-controls {
            padding: 0;
            margin: 0 0 10px;
            @media all and (min-width: 768px) {
                // margin-right: 20px;
                margin-top: 10px;
            }
        }

        .v-label {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
        }

        .v-icon.v-icon {
            font-size: 16px;
            text-align: center;
        }

        .v-input--selection-controls__ripple {
            left: -13px;
        }
    }
    .v-input--selection-controls__ripple {
        display: none;
    }

    .base-checkbox.v-input--is-label-active {
        .v-input--selection-controls__input {
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        }

        .v-icon.v-icon {
            color: #fff !important;
        }
    }
</style>
